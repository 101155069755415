<template>
  <!----->
  <div>
    <bfc-bar></bfc-bar>
    <lab-menu></lab-menu>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>

    <v-card>
      <v-card-title> Shipping Information </v-card-title>
      <v-card-text>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="text-left">
              {{ eForm.doctorId.shippingAddress.firtname }}
              {{ eForm.doctorId.shippingAddress.lastname }}<br />
              Tel. {{ eForm.doctorId.memberId.mobile }}<br />
              {{ eForm.doctorId.shippingAddress.clinicName }}
              <br />
              {{ eForm.doctorId.shippingAddress.address }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-title> Tracking Information </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col md="12" sm="12">
              <v-text-field
                label="shipping Name"
                v-model="eForm.shippingInformation.shippingName"
                id="shippingName"
                required
                :rules="requiredRules"
              >
              </v-text-field>
              <v-text-field
                label="Tracking No."
                v-model="eForm.shippingInformation.trackingNo"
                id="trackingNo"
                required
                :rules="requiredRules"
              >
              </v-text-field>
              <v-text-field
                label="Shipping Date/Time"
                v-model="eForm.shippingInformation.shippingDate"
                id="shippingDate"
                placeholder="ex. 2020-01-01 12.00"
                required
                :rules="requiredRules"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="Save"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//import paymentComponent from "../payment/payment";
import SystemBar from "../../library/system-bar/system-bar";
import LabMenu from "../menubar/menubar";
import axios from "axios";

import { bfcPackage } from "../../config/config.js";

export default {
  components: {
    "lab-menu": LabMenu,
    "bfc-bar": SystemBar,
  },
  data: () => {
    return {
      packages: bfcPackage,
      hasError: false,
      caseId: "",
      caseNo: "",
      custId: "",
      doctorId: "",
      clinicId: "",
      doctor: "",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      actionCase: "Update Shipping Information",

      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/lab/dashboard",
        },
        {
          text: "Shipping Information",
          disabled: true,
          href: "/lab/",
        },
      ],
      eForm: {
        shippingInformation: {
          shippingName: "",
          trackingNo: "",
          shippingDate: "",
          updatedAt: "",
        },
      },
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  mounted() {
    this.init();
    //this.doctor = this.$cookies.get("Profile").doctor[0];
    // this.packages = bfcPackage;
    //console.log(this.doctor);
  },
  computed: {
    ...mapGetters({
      //getAuth: "BFC/getAuth",
      //getDoctorInfo: "BFC/getDoctorInfo",
      //getCaseInfo: "BFC/getCaseInfo",
    }),
    info() {
      //console.log(this.$route.params.caseNo);
      return this.getCaseInfo(this.$route.params.caseNo);
    },
    caseData() {
      return this.eForm;
    },
    requiredFields() {
      return {
        shippingName: this.eForm.shippingInformation.shippingName,
        trackingNo: this.eForm.shippingInformation.trackingNo,
        shippingDate: this.eForm.shippingInformation.shippingDate,
      };
    },
  },
  methods: {
    ...mapActions({
      signout: "BFC/Logout",
      ///createDraft: "BFC/createDraftCase",
      //removeImage: "BFC/removeDoctorDraftImage",
    }),
    async DraftStep(n) {
      // check require
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    async toPayment(n) {
      if (n === this.nSteps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    // async save(n) {
    //   console.log("nextStep");
    //   let breakForEachException = {};
    //   try {
    //     await Object.keys(this.requiredFields).forEach((f) => {
    //       //console.log(this.requiredField[f]);
    //       if (!this.requiredFields[f]) {
    //         this.hasError = true;
    //         document.getElementById(f).focus();
    //         throw breakForEachException;
    //       }
    //     });
    //   } catch (e) {
    //     if (e != breakForEachException) throw e;
    //     console.log(e);
    //   }
    // },

    async init() {
      try {
        //let payload
        await axios
          .post(
            this.url + "/api/bfc/v1/case/caseInfo",
            { caseNo: this.$route.params.caseNo }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              let info = response.data.data[0];
              this.caseId = info._id;
              this.doctorId = info.doctorId;
              this.custId = info.custId;
              console.log(info);
              console.log(typeof info.shippingInformation);
              if (typeof info.shippingInformation === "undefined") {
                this.eForm = {
                  ...{
                    shippingInformation: {
                      shippingName: "",
                      trackingNo: "",
                      shippingDate: "",
                      updatedAt: "",
                    },
                  },
                  ...info,
                };
              } else {
                this.eForm = info;
              }
              console.log(this.eForm);
            } else {
              // show error
              console.log("error response");
            }
          });

        this.breadcrumbs.push({
          text:
            "Case : " +
            this.$route.params.caseNo +
            " : " +
            this.eForm.caseDetail.patient_name,
          disabled: true,
          href: "#",
        });
      } catch (e) {
        //
        console.log(e);
      }
    },
    logout() {
      this.signout();
      this.$router.push("/lab");
    },

    //----------------------

    async Save() {
      let breakForEachException = {};
      this.eForm.shippingInformation.updatedAt = new Date().toISOString();
      try {
        let payload = {
          caseId: this.caseId,
          case: { shippingInformation: { ...this.eForm.shippingInformation } },
        };
        //let hasError = false;

        console.log(payload);
        //console.log(selectPackage);
        await Object.keys(this.requiredFields).forEach((f) => {
          //console.log(this.requiredField[f]);
          if (!this.requiredFields[f]) {
            this.hasError = true;
            document.getElementById(f).focus();
            throw breakForEachException;
          }
        });

        await axios
          .post(
            this.url + "/api/bfc/v1/lab/updateShipping",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            if (response.data.statusCode == 0) {
              alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        if (e != breakForEachException) throw e;
        console.log(e);
      }
    },
  },
  watch: {
    caseData() {
      //console.log();
      console.log("--------------------");
      console.log(this.caseData);
    },
    eForm: {
      //console.log();
      handler(val) {
        console.log(val);
        console.log("--------------------");
        //console.log(this.eForm.labUpload);
      },
    },
  },
};
</script>
<style scope type="css">
.bfc_li_teeth2extract,
.bfc_li_teeth2extract li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_teeth2extract_cbx {
  margin-left: 5.4px;
}

.bfc_li_RelieveCrowdingByStripping {
  display: inline;
  margin-left: 175px;
}

.bfc_li_RelieveCrowdingByStripping.mandible {
  display: inline;
  margin-left: 210px;
}

.bfc_li_RelieveCrowdingByStripping li {
  font-size: 0.97em;
  display: inline;
}
/*
.bfc_li_RelieveCrowdingByStripping_cbx {
  /*padding-left: 20px;
}*/
.padding14 {
  padding-left: 14px;
}
.padding16 {
  padding-left: 16px;
}

.padding19 {
  padding-left: 19px;
}

.padding18 {
  padding-left: 19px;
}

.padding22 {
  padding-left: 22px;
}

.padding24 {
  padding-left: 24px;
}

.padding26 {
  padding-left: 26px;
}

.padding30 {
  padding-left: 30px;
}

/************/
.attachment {
  position: relative !important;
  display: block !important;
  width: 220px !important;
  height: auto;
  float: left;
}
.attachment ul {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  /*float: left;*/
  margin-block-start: em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.attachment li {
  font-size: 0.97em !important;
  padding-left: 6px;
}
.attachment li:nth-child(2n-1) {
  background-color: #dfdfdf;
  width: 100%;
}

.quadrant {
  position: relative !important;
  display: inline-block !important;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  /*float: left;*/
}
.quadrant li {
  font-size: 0.97em !important;
  text-align: center;
  margin-left: -32px;
  margin-right: 32px;
}
.uploadForm {
  display: block;
  width: 100% !important;
}
</style>
